.Resume-Section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.Resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
}

.react-pdf__Page__textContent {
    display: none;
}

.Btn-Resume-Download {
    background: rgb(119, 142, 213) !important;
}

.Btn-Resume-Download:hover {
    background: rgb(129, 155, 234) !important;
}