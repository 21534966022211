.Social {
    color: white !important;
    margin-top: 30vh !important;
}

.Social-Links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

@media (max-width: 767px) {
    .Social {
        margin-top: 15vh !important;
        margin-bottom: 5vh !important;
    }
}