.App {
	text-align: center;
}

#no-scroll {
	overflow: hidden;
	height: 100vh;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #212950;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(119, 142, 213, 0.959);
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(129, 155, 234, 0.911);
	border-radius: 12px;
}