.AboutMe {
    color: white !important;
    padding-bottom: 20px !important;
    text-align: center;
    height: 100vh;
}

.AboutMe-Body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.MyImg {
    justify-content: center !important;
}

.MyImg img {
    height: 50vh;
}

@media (max-width: 767px) {
    .AboutMe {
        margin-bottom: 6em !important;
    }

    .MyImg {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}