@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

html,
body,
#root {
    margin: 0;
    font-family: "Raleway", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0a192f;
}