.Footer {
    background-color: #15243a60;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}

.Footer-Copywright {
    text-align: center !important;
}

.Footer-Body {
    z-index: 1;
    text-align: center !important;
}

@media (max-width: 767px) {
    .Footer-Copywright {
        text-align: center !important;
    }

    .Footer-Body {
        text-align: center !important;
    }
}

.Footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.Footer-Icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}

.blockquote-Footer {
    color: #a588c0 !important;
}

.Social-Icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}