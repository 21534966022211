.SkillsOrTools-Icon {
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(117, 167, 242, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(33, 41, 80, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

.SkillsOrTools-Icon svg{
    font-size: 4.5em !important;
}

@media (max-width: 767px) {
    .SkillsOrTools-Icon {
        margin: 10px !important;
    }
}

.SkillsOrTools-Icon:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(129, 155, 234, 0.883) !important;
    background-color: rgba(129, 155, 234, 0.883) !important;
}