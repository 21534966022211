.Skills-Row {
    color: white !important;
    padding-bottom: 20px !important;
    text-align: center;
    justify-content: center !important;
}

@media (max-width: 767px) {
    .Skills-Row {
        margin-top: 100vh !important;
    }
}