.Projects-Section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
    width: 80vw !important;
}

.Projects-Caption {
    color: white !important;
    text-shadow: 0px 3px 5px black !important;
    background-color: rgba(10, 25, 47, 0.9);
    border-radius: 30px;
}