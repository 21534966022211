.Tools-Row {
    color: white !important;
    margin-top: 30vh !important;
    padding-bottom: 20px !important;
    text-align: center;
    justify-content: center !important;
}

@media (max-width: 767px) {
    .Tools-Row {
        margin-top: 15vh !important;
        margin-bottom: 5vh !important;
    }
}