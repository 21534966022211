.Home {
    position: relative;
}

.Home-Header {
    padding: 30vh 0 2rem !important;
    height: 100vh;
    color: whitesmoke;
    text-align: left;
}

.Home-Header-Col {
    padding-top: 80px !important;
    padding-left: 50px !important;
}

.Home-Header-Col h1 {
    margin: 0px 0px 0px 4px;
    font-size: clamp(14px, 5vw, 16px) !important;
    color: rgb(117, 167, 242);
    line-height: 1.1;
}

.Heading-Name {
    margin: 0;
    font-size: clamp(40px, 8vw, 80px)
}

.Heading-Title {
    margin-top: 5px;
    color: #8892b0;
    line-height: 0.9;
    font-size: clamp(20px, 5vw, 40px)
}

.Home-Content {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

@media (max-width: 767px) {
    .Home-Header {
        padding: 30vh 0 !important;
    }

    .Home-Pic {
        width: 80% !important;
        margin-left: 18vw !important;
    }

    .Home-Content {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
