#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #0a192f;
    background-image: url(../../../Assets/loader.svg);
    background-repeat: no-repeat;
    background-position: center;
}

#preloader-none {
    opacity: 0;
}